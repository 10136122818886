<template>
      <v-dialog
        ref="dialog"
        v-model="modal"
        persistent
        width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                :label="label"
                :light="light"
                :dark="dark"
                :disabled="disabled"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"/>
        </template>
        <v-time-picker
            v-model="time"
            :dark="dark"
            full-width
            ampm-in-title
            :allowed-minutes="allowedMinutes"
            :light="light">
            <v-spacer />
            <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="ok">OK</v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>
import { DateTime } from 'luxon/src/luxon';

export default {
    name: 'BT-Time-Picker',
    data: function() {
        return {
            time: null,
            modal: null,
        }
    },
    mounted() {
        if (this.returnMinuteOfDay) {
            var d = DateTime.utc(2000, 1, 1, 0, 0, 0);
            d = d.plus({ minutes: this.value });
            this.time = d.toFormat('T');
        }
        else {
            this.time = this.value;
        }
    },
    watch: {
        value: function(val) {
            if (this.time !== val) {
                if (this.returnMinuteOfDay) {
                    var d = DateTime.utc(2000, 1, 1, 0, 0, 0);
                    d = d.plus({ minutes: this.value });
                    this.time = d.toFormat('T');
                }
                else {
                    this.time = this.value;
                }
            }            
        }
    },
    computed: {
        allowedMinutes() {
            return this.isHourly ? v => v == 0 : null
        }
    },
    props: {
        value: null,
        dark: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        light: {
            type: Boolean,
            default: false
        },
        isHourly: {
            type: Boolean,
            default: true
        },
        returnMinuteOfDay: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ok() {
            var v = this.time;

            if (this.returnMinuteOfDay) {
                var d = DateTime.fromFormat(this.time, 'T');
                var dif = d.diff(DateTime.fromFormat('00:00', 'T'));
                v = dif.as('minutes');
            }
            
            this.$emit('input', v);
            this.$emit('change', v);
            this.modal = false;
        }
    }
}
</script>